<template>
  <div class="container-fluid ml-10">
    <div>
      <TbVisit />
    </div>
  </div>
</template>

<script>
import TbVisit from "@/components/visit/TbPending.vue";
export default {
  components: { TbVisit },
};
</script>

<style>
</style>
